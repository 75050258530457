exports.components = {
  "component---src-pages-404-ko-js": () => import("./../../../src/pages/404.ko.js" /* webpackChunkName: "component---src-pages-404-ko-js" */),
  "component---src-pages-about-ebl-en-js": () => import("./../../../src/pages/about-ebl.en.js" /* webpackChunkName: "component---src-pages-about-ebl-en-js" */),
  "component---src-pages-about-ebl-ko-js": () => import("./../../../src/pages/about-ebl.ko.js" /* webpackChunkName: "component---src-pages-about-ebl-ko-js" */),
  "component---src-pages-common-q-and-a-en-js": () => import("./../../../src/pages/common-q-and-a.en.js" /* webpackChunkName: "component---src-pages-common-q-and-a-en-js" */),
  "component---src-pages-common-q-and-a-ko-js": () => import("./../../../src/pages/common-q-and-a.ko.js" /* webpackChunkName: "component---src-pages-common-q-and-a-ko-js" */),
  "component---src-pages-contact-and-location-en-js": () => import("./../../../src/pages/contact-and-location.en.js" /* webpackChunkName: "component---src-pages-contact-and-location-en-js" */),
  "component---src-pages-contact-and-location-ko-js": () => import("./../../../src/pages/contact-and-location.ko.js" /* webpackChunkName: "component---src-pages-contact-and-location-ko-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-ko-js": () => import("./../../../src/pages/index.ko.js" /* webpackChunkName: "component---src-pages-index-ko-js" */),
  "component---src-pages-programs-adult-classes-en-js": () => import("./../../../src/pages/programs/adult-classes.en.js" /* webpackChunkName: "component---src-pages-programs-adult-classes-en-js" */),
  "component---src-pages-programs-adult-classes-ko-js": () => import("./../../../src/pages/programs/adult-classes.ko.js" /* webpackChunkName: "component---src-pages-programs-adult-classes-ko-js" */),
  "component---src-pages-programs-library-membership-en-js": () => import("./../../../src/pages/programs/library-membership.en.js" /* webpackChunkName: "component---src-pages-programs-library-membership-en-js" */),
  "component---src-pages-programs-library-membership-ko-js": () => import("./../../../src/pages/programs/library-membership.ko.js" /* webpackChunkName: "component---src-pages-programs-library-membership-ko-js" */),
  "component---src-pages-programs-summer-winter-programs-en-js": () => import("./../../../src/pages/programs/summer-winter-programs.en.js" /* webpackChunkName: "component---src-pages-programs-summer-winter-programs-en-js" */),
  "component---src-pages-programs-summer-winter-programs-ko-js": () => import("./../../../src/pages/programs/summer-winter-programs.ko.js" /* webpackChunkName: "component---src-pages-programs-summer-winter-programs-ko-js" */),
  "component---src-pages-programs-young-readers-en-js": () => import("./../../../src/pages/programs/young-readers.en.js" /* webpackChunkName: "component---src-pages-programs-young-readers-en-js" */),
  "component---src-pages-programs-young-readers-ko-js": () => import("./../../../src/pages/programs/young-readers.ko.js" /* webpackChunkName: "component---src-pages-programs-young-readers-ko-js" */)
}

